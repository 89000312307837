import { atom } from 'jotai';

import { MakeModelAtom } from '@core/Entities/Search/Atoms/MakeAtom.entity';

export const modelAtom = atom<MakeModelAtom>({
  key: 'any_model',
  displayName: 'Any Model',
  count: 0,
  make: 'anymodel',
});
